<template>
  <div>
    <div
      v-for="(item, i) in items"
      :key="i"
      :style="{'animation-delay': .20*i + 's'}"
      class="lista"
    >
      <div @click="$set(openItems, i, !openItems[i])">
        <slot name="header" :item="item" :open="openItems[i]" :i="i" />
      </div>
      <keep-alive>
        <div v-show="openItems[i]">
          <slot name="content" :item="item" :i="i" />
        </div>
      </keep-alive>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lista {
  animation: fade-down .1s ease-out both;
}

@keyframes fade-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

.crossfade-enter-active, .crossfade-leave-active {
  animation: none !important;
  transition: opacity .15s ease-in-out !important;
}

.crossfade-enter, .crossfade-leave-to {
  opacity: 0 !important;
}
</style>

<script>
export default {
  data: () => ({
    openItems: [],
  }),
  props: {
    items: { type: Array, default: () => [] },
  },
}
</script>
