<template>
  <section class="secao-transparencia container-base">
    <section class="breadcrumb">
      <router-link :to="{ name: 'Home' }" class="breadcrumbT">
        <img src="@/assets/BreadcrumbHome.png" alt="Home" class="breadcrumbImg">
      </router-link>
      <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
      <router-link :to="{ name: 'Transparencia' }" class="breadcrumbT">
        <img src="@/assets/listaBreadcrumb.png" alt="Lista" class="breadcrumbImg">
      </router-link>
      <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
      <h1>Transparência - Projetos</h1>
    </section>

    <p>Clique em um projeto para visualizar os documentos</p>
    <div class="accordion-wrapper">
      <accordion :items="projetos">
        <template #header="{item: projeto, open}">
          <div
            class="projeto"
            :class="{open: projetoAberto === projeto.id}"
          >
            <transition name="crossfade" mode="out-in">
              <span v-if="!loading" class="titulo">{{ projeto.nome }}</span>
              <span v-else class="tituloSkeleton">Carregando{{ Array(60).fill('.').join('') }}</span>
            </transition>
            <span class="icone">{{ open ? '-' : '+' }}</span>
          </div>
        </template>
        <template #content="{item: projeto}">
          <div v-if="!loading" class="projeto-conteudo">
            <accordion v-if="projeto.categorias.length" :items="projeto.categorias">
              <template #header="{item: categoria, open, i}">
                <div class="categoria" :style="{'animation-delay': .15*i + 's'}">
                  <span class="titulo">{{ categoria.nome }}</span>
                  <span class="icone">{{ open ? '-' : '+' }}</span>
                </div>
              </template>
              <template #content="{item: categoria}">
                <a v-for="(doc, i) in categoria.documentos" :key="i"
                   class="documento"
                   :href="doc.arquivo? doc.arquivo.data.full_url : null"
                   :style="{'animation-delay': .15*i + 's'}"
                   target="_blank"
                >
                  <img src="@/assets/ic_download_arquivo.svg" class="icone-download">
                  <span>{{ doc.titulo }}</span>
                </a>
              </template>
            </accordion>
            <div v-else>
              <p>Nenhum documento encontrado para este projeto. Entre em contato para mais informações.</p>
            </div>
          </div>
        </template>
      </accordion>
    </div>
  </section>
</template>

<style lang="scss" scoped>
h1{
  display: block;
  margin: 3px 0 0 9px;
  font-size: 25px;
}

.breadcrumb{
  display: flex;
}

.breadcrumbImg{
  margin: 6px 9px 0 9px;
  width: 25px;
  height: 25px;
}

.iconImg{
  margin: 13px 9px 0 9px;
  width: 6px;
  height: 10px;
}

@media (max-width: 430px) {
  .iconImg{
  margin: 13px 9px 0 9px;
  width: 4px;
  height: 8px;
  }

  .breadcrumbImg{
  margin: 6px 9px 0 9px;
  width: 20px;
  height: 20px;
  }

  h1{
  display: block;
  margin: 5px 0 0 9px;
  font-size: 18px;
  }

  .tituloSkeleton{
    width: 100%;
  }
}

.secao-transparencia {
  margin-top: 50px;
  margin-bottom: 50px;
}

.accordion-wrapper {
  border-bottom: 1px solid gray;
}

.projeto {
  display: flex;
  padding: 12px 8px;
  border-top: 1px solid gray;
  cursor: pointer;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: background-color .2s;

  user-select: none;

  &:hover {
    background-color: var(--ahbb-green-light-3);
  }

  &:active{
    background-color: var(--ahbb-green-light-2);
  }

  &.open {
    &:hover {
      background-color: var(--ahbb-green-light-2);
    }

    &:active{
      background-color: var(--ahbb-green-light-1);
    }
  }

  .icone {
    width: 16px;
    text-align: center;
  }

  .tituloSkeleton{
    color: transparent;
    animation: pulse-bg 1s infinite;
  }
}

.crossfade-enter-active, .crossfade-leave-active {
  animation: none !important;
  transition: opacity .15s ease-in-out !important;
}

.crossfade-enter, .crossfade-leave-to {
  opacity: 0 !important;
}

.projeto-conteudo {
  padding: 4px 0px;
  padding-left: 12px;
}

.projeto.open, .projeto-conteudo {
  background-color: var(--ahbb-green-light-3);
}

.categoria {
  display: flex;

  padding: 10px 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  cursor: pointer;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  animation: fade-down .1s ease-out both;

  user-select: none;

  .icone {
    width: 16px;
    font-weight: normal;
    font-size: larger;
    text-align: center;
  }

  &:hover {
    background-color: var(--ahbb-green-light-2);
  }

  &:active{
    background-color: var(--ahbb-green-light-1);
  }
}

.documento {
  display: flex;
  flex: 1 0 320px;

  flex-direction: row;
  align-items: center;

  border-top: 1px solid gray;

  transition: background-color .2s;

  text-decoration: none;
  color: var(--ahbb-blue);

  animation: fade-down .1s ease-out both;

  &:not(.loading) {
    &:hover {
      background-color: var(--ahbb-green-light-2);
      cursor: pointer;
    }

    &:active {
      background-color: var(--ahbb-green-light-1);
    }
  }

  .icone-download {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: rgba(233, 233, 233, 0.993);
  }
  100% {
    background-color: #eee;
  }
}

@keyframes fade-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
}
</style>

<script>
import Accordion from '@/components/Accordion.vue'
import cms from '@/lib/cms.js'

export default {
  name: 'TransparenciaProjetos',
  components: {
    Accordion,
  },
  data: () => ({
    projetos: Array(20).fill({}),
    projetoAberto: '',
    loading: true,
  }),
  async created() {
    const projs = await cms.getItems(
      'onde_estamos',
      { fields: 'nome,documentos.*.*.*,id' },
    )
    
    if (this.$route.query.projectName)
      this.projetoAberto = this.$route.query.projectName
    
    this.projetos = projs.data.map(p => {
      const cats = {}
      p.documentos.forEach(d => {
        if (!(d.categoria in cats))
          cats[d.categoria] = []
        cats[d.categoria].push(d)
      })

      return {
        nome: p.nome,
        id: p.id,
        categorias: Object.entries(cats).map(([k, v]) => ({ nome: k, documentos: v })),
      }
    })
    this.loading = false
  },
}
</script>
